module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portknox.net","short_name":"Portknox","start_url":"/","background_color":"#000","theme_color":"#009EE0","display":"standalone","icon":"static/portknox_lock.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc13ae6709d55b3fbdef33f00cea1893"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
